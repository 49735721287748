const webData = [
  {
    id: "1",
    type: "services",
    title: "Website Design and Development",
    desc: "Guranted Digital Presence",
    content:
      "Experienced website designer proficient in website development, specializing in crafting visually appealing and user-friendly online platforms. With expertise in modern design principles and cutting-edge technologies, I create custom websites tailored to client's needs, ensuring optimal functionality and aesthetic appeal.",
    keywords: "website design, web design, web development, responsive design, UI/UX design, Bhagirath Technologies",
    properties: [
      "Custom Website Design and Technologies.",
      "HTML, CSS, JavaScript, and Web Development Tools.",
      "Search Engine Optimization to improve online visibility.",
      "Improve productivity and performance.",
      "Minimize your service expenses.",
      "Streamline admin and reporting functionality. ",
    ],
    image: "../assets/images/services/1.webp",
    icons: [
      "../assets/images/icons/Website Design and Development/1.svg",
      "../assets/images/icons/Website Design and Development/2.svg",
      "../assets/images/icons/Website Design and Development/3.svg",
      "../assets/images/icons/Website Design and Development/4.svg",
      "../assets/images/icons/Website Design and Development/5.svg",
      "../assets/images/icons/Website Design and Development/6.svg",
      "../assets/images/icons/Website Design and Development/7.svg",
      "../assets/images/icons/Website Design and Development/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "300",
  },
  {
    id: "2",
    type: "services",
    title: "Web Applications Design",
    desc: "Guranted Digital Presence",
    content:
      "Proficient in developing user-friendly and visually captivating web application interfaces with precise attention to detail in design. Focused on delivering seamless user experiences and ensuring optimal functionality across all elements of web application design. Elevating digital interactions through carefully crafted application design tailored for web-based platforms.",
    keywords: "Web application development, custom web application, web app design, Bhagirath Technologies.",
    properties: [
      "Customised Web Application interface design.",
      "User-friendly and intuitive navigation.",
      "Responsive layout for various devices.",
      "Collaboration with clients for tailored solutions.",
      "Optimization for optimal performance.",
      "Focus on enhancing user experience and functionality.",
    ],
    image: "../assets/images/services/2.webp",
    icons: [
      "../assets/images/icons/Web Applications Design/1.svg",
      "../assets/images/icons/Web Applications Design/2.svg",
      "../assets/images/icons/Web Applications Design/3.svg",
      "../assets/images/icons/Web Applications Design/4.svg",
      "../assets/images/icons/Web Applications Design/5.svg",
      "../assets/images/icons/Web Applications Design/6.svg",
      "../assets/images/icons/Web Applications Design/7.svg",
      "../assets/images/icons/Web Applications Design/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "600",
  },
  {
    id: "3",
    type: "services",
    title: "Custom Software Development",
    desc: "Guranted Digital Presence",
    content:
      "Specializing in custom development services, we excel in software development, creating tailored solutions to meet your unique needs. Our expertise lies in developing innovative software that optimizes functionality and user satisfaction. Trust us to deliver exceptional solutions crafted to drive your business forward.",
    keywords: "custom software development, bespoke software, software solutions, Bhagirath Technologies.",
    properties: [
      "Software services to unique business needs.",
      "Expertise in custom software development.",
      "Innovative approach to optimizing functionality.",
      "Attention to user satisfaction and seamless integration.",
      "Ongoing support and maintenance for sustained performance.",
      "Drive business growth through custom software solutions.",
    ],
    image: "../assets/images/services/3.webp",
    icons: [
      "../assets/images/icons/Custom Software Development/1.svg",
      "../assets/images/icons/Custom Software Development/2.svg",
      "../assets/images/icons/Custom Software Development/3.svg",
      "../assets/images/icons/Custom Software Development/4.svg",
      "../assets/images/icons/Custom Software Development/5.svg",
      "../assets/images/icons/Custom Software Development/6.svg",
      "../assets/images/icons/Custom Software Development/7.svg",
      "../assets/images/icons/Custom Software Development/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "900",
  },
  {
    id: "4",
    type: "services",
    title: "E-Commerce Solutions",
    desc: "Guranted Digital Presence",
    content:
      "Offering customized E-Commerce services to suit various business requirements, we empower enterprises to excel in the digital realm. Rely on our expertise to boost your online presence and foster growth through tailored E-Commerce solutions.",
    keywords: "e-commerce application development, e-commerce platform, Bhagirath Technologies.",
    properties: [
      "Website development and design tailored.",
      "Inventory management systems.",
      "Responsive and mobile-friendly design.",
      "Seamless checkout process.",
      "customer account management features.",
      "Analytics and reporting for performance tracking.",
      "Marketing and promotional tools. (eg; discount codes, Email marketing integration).",
    ],
    image: "../assets/images/services/4.webp",
    icons: [
      "../assets/images/icons/E-Commerce Solutions/1.svg",
      "../assets/images/icons/E-Commerce Solutions/2.svg",
      "../assets/images/icons/E-Commerce Solutions/3.svg",
      "../assets/images/icons/E-Commerce Solutions/4.svg",
      "../assets/images/icons/E-Commerce Solutions/5.svg",
      "../assets/images/icons/E-Commerce Solutions/6.svg",
      "../assets/images/icons/E-Commerce Solutions/7.svg",
      "../assets/images/icons/E-Commerce Solutions/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "1200",
  },
  {
    id: "5",
    type: "services",
    title: "Logo Designs",
    desc: "Guranted Digital Presence",
    content:
      "Uncover the craftsmanship behind our logo design services, where creativity seamlessly blends with precision in each unique design. Delve into a realm of captivating designs meticulously tailored to enhance your brand's identity and make a memorable impact. Rely on our expertise to materialize your vision with carefully curated logo designs that connect with your audience.",
    keywords: "logo design, brand identity, logo creation, graphic design, Bhagirath Technologies",
    properties: [
      "Custom logo creation for your brand identity.",
      "Expert designers crafting unique logos.",
      "Delivery of high-quality logo files in various formats.",
      "Timely completion of logo design project.",
      "Dedicated support throughout the design process.",
      "Attention to detail to capture your brand essence accurately.",
    ],
    image: "../assets/images/services/5.webp",
    icons: [
      "../assets/images/icons/Logo Designs/1.svg",
      "../assets/images/icons/Logo Designs/2.svg",
      "../assets/images/icons/Logo Designs/3.svg",
      "../assets/images/icons/Logo Designs/4.svg",
      "../assets/images/icons/Logo Designs/5.svg",
      "../assets/images/icons/Logo Designs/6.svg",
      "../assets/images/icons/Logo Designs/7.svg",
      "../assets/images/icons/Logo Designs/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "300",
  },
  {
    id: "6",
    type: "services",
    title: "Banner & Poster Design",
    desc: "Guranted Digital Presence",
    content:
      "Discover our wide array of services covering banner and poster designs, where we seamlessly blend creativity and functionality to provide impactful visual solutions. Our team of expert designers focuses on creating captivating designs that are customized to meet your unique requirements. Whether you need a striking banner design to boost your online presence or a compelling poster design to advertise your event or business, we've got you covered.",
    keywords: "banner design, poster design, graphic design, promotional materials, Bhagirath Technologies",
    properties: [
      "Customized banner and poster design to your specific needs. ",
      "Experienced designers with a keen eye for detail and creativity.",
      "High-quality designs crafted to promote your event/business.",
      "Flexible revisions to ensure satisfaction with the final product.",
      "Attention visuals that effectively communicate your message.",
      "Versatile formats for easy integration across various platforms and mediums.",
    ],
    image: "../assets/images/services/6.webp",
    icons: [
      "../assets/images/icons/Banner & Poster Design/1.svg",
      "../assets/images/icons/Banner & Poster Design/2.svg",
      "../assets/images/icons/Banner & Poster Design/3.svg",
      "../assets/images/icons/Banner & Poster Design/4.svg",
      "../assets/images/icons/Banner & Poster Design/5.svg",
      "../assets/images/icons/Banner & Poster Design/6.svg",
      "../assets/images/icons/Banner & Poster Design/7.svg",
      "../assets/images/icons/Banner & Poster Design/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "600",
  },
  {
    id: "7",
    type: "services",
    title: "Print Media",
    desc: "Guranted Digital Presence",
    content:
      "Discover the potential of print media with our tailored services. From captivating brochures to eye-catching banners, memorable business cards, and elegant invitations, we craft materials that make an impact. Our expertise covers striking book covers, attention-grabbing posters, and innovative product packaging. Whether it's impactful signage, customizable calendars, or any print requirement, we're here to bring your vision to life with creativity and precision.",
    keywords: "print media services, printing solutions, brochures, flyers, business cards, Bhagirath Technologies",
    properties: [
      "Design and production of banners.",
      "Creation of brochures for informational or promotional purposes.",
      "Custom business card design and printings.",
      "Crafting elegant invitations for events or special occasions.",
      "Designing eye-catching book covers for publication.",
      "Innovative product packaging solutions.",
    ],
    image: "../assets/images/services/7.webp",
    icons: [
      "../assets/images/icons/Print Media/1.svg",
      "../assets/images/icons/Print Media/2.svg",
      "../assets/images/icons/Print Media/3.svg",
      "../assets/images/icons/Print Media/4.svg",
      "../assets/images/icons/Print Media/5.svg",
      "../assets/images/icons/Print Media/6.svg",
      "../assets/images/icons/Print Media/7.svg",
      "../assets/images/icons/Print Media/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "900",
  },
  {
    id: "8",
    type: "services",
    title: "Figma Design",
    desc: "Guranted Digital Presence",
    content:
      "Explore the power of Figma Design, the ultimate platform for seamless web and app design. From crafting stunning websites with Figma's intuitive interface to creating pixel-perfect UI designs, our expertise spans Figma UI Design and beyond. Convert your designs effortlessly with Figma to HTML and CSS integration. Elevate your projects with the industry's leading design tool – Figma",
    keywords: "Figma design, UI/UX design, interface design, Bhagirath Technologies",
    properties: [
      "UI/UX Design in Figma.",
      "Create, share, and test designs for the website.",
      "Teams to standardize styles, variables, and components.",
      "A Complete Solution for Creating Design Prototypes.",
      "Real-time collaboration.",
      "Cloud-Based Website Designing Tool. ",
    ],
    image: "../assets/images/services/8.webp",
    icons: [
      "../assets/images/icons/Figma Design/1.svg",
      "../assets/images/icons/Figma Design/2.svg",
      "../assets/images/icons/Figma Design/3.svg",
      "../assets/images/icons/Figma Design/4.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "1200",
  },
  {
    id: "9",
    type: "services",
    title: "Digital Marketing",
    desc: "Guranted Digital Presence",
    content:
      "Step into digital marketing, where strategic online approaches intersect with SEO advertising and Search Engine Marketing tactics. Whether enhancing visibility through SEO marketing or harnessing the potency of SEM, our solutions deliver tangible results, elevating your online footprint.",
    keywords: "digital marketing, SEO, PPC, social media marketing, email marketing, Bhagirath Technologies",
    properties: [
      "SEO strategies to improve online visibility and ranking.",
      "SEM campaigns for effective ad placement and audience targeting.",
      "Social Media Marketing to enhance brand presence and engagement.",
      "Content marketing strategies to attract and retain customers.",
      "PPC advertising management to optimize ad performance and ROI.",
      "Analytics and reporting to track campaigns and drive continuous improvement.",
    ],
    image: "../assets/images/services/9.webp",
    icons: [
      "../assets/images/icons/Digital Marketing/1.svg",
      "../assets/images/icons/Digital Marketing/2.svg",
      "../assets/images/icons/Digital Marketing/3.svg",
      "../assets/images/icons/Digital Marketing/4.svg",
      "../assets/images/icons/Digital Marketing/5.svg",
      "../assets/images/icons/Digital Marketing/6.svg",
      "../assets/images/icons/Digital Marketing/7.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "300",
  },
  {
    id: "10",
    type: "services",
    title: "Social Media Marketing",
    desc: "Guranted Digital Presence",
    content:
      "Immerse yourself in social media marketing, where creative strategies intersect with compelling content creation. With specialized social media advertising and detailed analytics, we enhance your brand's visibility and engagement, guaranteeing tangible outcomes at each stage of your journey.",
    keywords: "social media marketing, SMM, social media management, social media advertising, Bhagirath Technologies",
    properties: [
      "Social media strategy development.",
      "Engaging in content creation and curation.",
      "Expert management of social media advertising campaigns.",
      "In-depth social media analytics and performance reporting.",
      "Ongoing social media platform optimization.",
      "Personalized consulting services for clients.",
    ],
    image: "../assets/images/services/10.webp",
    icons: [
      "../assets/images/icons/Social Media Management Marketing/1.svg",
      "../assets/images/icons/Social Media Management Marketing/2.svg",
      "../assets/images/icons/Social Media Management Marketing/3.svg",
      "../assets/images/icons/Social Media Management Marketing/4.svg",
      "../assets/images/icons/Social Media Management Marketing/5.svg",
      "../assets/images/icons/Social Media Management Marketing/6.svg",
      "../assets/images/icons/Social Media Management Marketing/7.svg",
      "../assets/images/icons/Social Media Management Marketing/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "600",
  },
  {
    id: "11",
    type: "services",
    title: "Google Ads Campaigns",
    desc: "Guranted Digital Presence",
    content:
      "Maximize your online visibility and drive targeted traffic with our expert Google Ads campaigns. From strategic AdWords management to cost-effective advertising solutions, we optimize your Adsense presence to boost business advertising and enhance your online reach. Explore our Google Ads services today for impactful search ads that deliver results.",
    keywords: "Google Ads, PPC, pay-per-click, advertising campaign, Bhagirath Technologies",
    properties: [
      "Tailored Google ads campaign creation and management.",
      "Strategic adword optimization for maximum ROI.",
      "Cost-effective advertising solutions tailored to your budget.",
      "Search ads to reach potential customers effectively.",
      "Google ads services including campaign setup, monitoring, and optimization.",
    ],
    image: "../assets/images/services/11.webp",
    icons: [
      "../assets/images/icons/Google Ads Campaigns/1.svg",
      "../assets/images/icons/Google Ads Campaigns/2.svg",
      "../assets/images/icons/Google Ads Campaigns/3.svg",
      "../assets/images/icons/Google Ads Campaigns/4.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "900",
  },
  {
    id: "12",
    type: "services",
    title: "Content Marketing",
    desc: "Guranted Digital Presence",
    content:
      "Elevate your brand's online presence with strategic content marketing solutions. From developing tailored content strategies for LinkedIn and websites to creating engaging digital marketing content, our agency specializes in crafting impactful messages that resonate with your audience. Partner with us for expert content creation services that drive results and boost your brand's visibility across social media platforms.",
    keywords: "content writing, copywriting, blog writing, article writing, social media content, Bhagirath Technologies",
    properties: [
      "Content marketing strategy development.",
      "Expert content creation services for various platforms.",
      "Comprehensive digital marketing content creation.",
      "Website content strategy consulting and implementation",
      "Content creation services to meet specific client needs",
      "Guidance on developing a content strategy aligned with business objectives.",
    ],
    image: "../assets/images/services/12.webp",
    icons: [
      "../assets/images/icons/Content Marketing/1.svg",
      "../assets/images/icons/Content Marketing/2.svg",
      "../assets/images/icons/Content Marketing/3.svg",
      "../assets/images/icons/Content Marketing/4.svg",
      "../assets/images/icons/Content Marketing/5.svg",
      "../assets/images/icons/Content Marketing/6.svg",
      "../assets/images/icons/Content Marketing/7.svg",
      "../assets/images/icons/Content Marketing/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "1200",
  },
  {
    id: "13",
    type: "services",
    title: "Brand Identity",
    desc: "Guranted Digital Presence",
    content:
      "Building a unique brand identity starts with a thorough brand strategy, ensuring coherence between your branding elements and resonating with your audience. Through strategic brand marketing, we enhance your brand's visibility and solidify its identity in the market.",
    keywords: "branding services, brand strategy, brand identity, brand messaging, visual design, Bhagirath Technologies",
    properties: [
      "Brand strategy development.",
      "Visual identity design, including logo design and brand aesthetics.",
      "Audience research and targeting to ensure resonance.",
      "Brand positioning and differentiation strategies.",
      "Implementation guidelines to maintain consistency across all touchpoints.",
      "Brand identity audits and reviews for ongoing improvement.",
    ],
    image: "../assets/images/services/13.webp",
    icons: [
      "../assets/images/icons/Brand Identity/1.svg",
      "../assets/images/icons/Brand Identity/2.svg",
      "../assets/images/icons/Brand Identity/3.svg",
      "../assets/images/icons/Brand Identity/4.svg",
      "../assets/images/icons/Brand Identity/5.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "300",
  },
  {
    id: "14",
    type: "services",
    title: "Mockup Design",
    desc: "Guranted Digital Presence",
    content:
      "Enhance your design process with our expert mockup design services, personalized to bring your vision to life. Our team specializes in creating stunning mockups that showcase your designs with precision and creativity, ensuring a seamless transition from concept to reality.",
    keywords: "mockup design, website mockup, app mockup, digital product design, Bhagirath Technologies",
    properties: [
      "Customized mockup design solutions tailored to your specific needs.",
      "High-quality mockups that accurately represent your designs and concepts.",
      "Expert design team dedicated to bringing your vision to life.",
      "Seamless integration of mockups into your design process.",
      "Quick turnaround times to meet project deadlines.",
      "Flexible and collaborative approach to ensure satisfaction with final mockup designs.",
    ],
    image: "../assets/images/services/14.webp",
    icons: [
      "../assets/images/icons/Art Direction/1.svg",
      "../assets/images/icons/Art Direction/2.svg",
      "../assets/images/icons/Art Direction/3.svg",
      "../assets/images/icons/Art Direction/4.svg",
      "../assets/images/icons/Art Direction/5.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "600",
  },
  {
    id: "15",
    type: "services",
    title: "Visual Design",
    desc: "Guranted Digital Presence",
    content:
      "Harness the power of visual design with our comprehensive services, encompassing Adobe Visual Design and AI-driven solutions to elevate your brand's presence. Our team of UX and UI visual designers crafts stunning visual graphic designs and creates seamless visual web designs, ensuring an exceptional user experience across all platforms",
    keywords: "visual design, web design, app design, branding design, graphic design, Bhagirath Technologies",
    properties: [
      "Visual design solutions tailored to your brand and objectives.",
      "Adobe Visual Design tools for high-quality graphics.",
      "Creative visual graphic design services for various purposes",
      "Utilization of AI technologies for innovative visual designs",
      "Development of visually stunning web designs to enhance online presence.",
      "Specialization in UX and UI design for optimal user experiences.",
    ],
    image: "../assets/images/services/15.webp",
    icons: [
      "../assets/images/icons/Visual Design/1.svg",
      "../assets/images/icons/Visual Design/2.svg",
      "../assets/images/icons/Visual Design/3.svg",
      "../assets/images/icons/Visual Design/4.svg",
      "../assets/images/icons/Visual Design/5.svg",
      "../assets/images/icons/Visual Design/6.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "900",
  },
  {
    id: "16",
    type: "services",
    title: "Illustration & Iconography",
    desc: "Guranted Digital Presence",
    content:
      "Transform your concept into compelling visuals through our Adobe Illustrator-centric illustration and icon design offerings. Our skilled team excels in producing bespoke icons and vector illustrations that invigorate your brand. Covering logo creation to website illustrations, we infuse creativity and accuracy into each endeavor, guaranteeing your visuals captivate and connect with your audience.",
    keywords: "illustration design, icon design, custom illustrations, custom icons, branding design, Bhagirath Technologies",
    properties: [
      "Customized illustrations and icons designed to your brand and requirements.",
      "Expertise in Adobe Illustrator for high-quality, professional visuals.",
      "Create custom icons and vector illustrations to enhance brand identity.",
      "Providing illustrations for various purposes, including logos, websites, and marketing materials.",
      "Attention to detail and creativity to ensure visually appealing and impactful designs.",
    ],
    image: "../assets/images/services/16.webp",
    icons: [
      "../assets/images/icons/Illustration & Iconography/1.svg",
      "../assets/images/icons/Illustration & Iconography/2.svg",
      "../assets/images/icons/Illustration & Iconography/3.svg",
      "../assets/images/icons/Illustration & Iconography/4.svg",
      "../assets/images/icons/Illustration & Iconography/5.svg",
      "../assets/images/icons/Illustration & Iconography/6.svg",
      "../assets/images/icons/Illustration & Iconography/7.svg",
      "../assets/images/icons/Illustration & Iconography/8.svg",
    ],
    class: "col-sm-6 col-md-4 col-lg-3 col-xxl-3",
    hashlinks: "service_detail",
    data_aos_delay: "1200",
  },
];

export default webData;

export const serviceItems = [
  {
    id: "1",
    icon: "fab fa-react",
    title: "Web Development",
    desc: "Entrust us to translate your ideas into an exceptional website that engages and resonates with your target audience. our custom web development services, tailored to resonate with your brand identity and deliver impactful outcomes.",
    hashlinks: "service_detail",
    properties: [
      "Website Design",
      "Web Application",
      "Custom Software Development",
      "E-Commerce Solution",
    ],
    links: ["/services/1", "/services/2", "/services/3", "/services/4"],
    data_aos_delay: "300",
  },
  {
    id: "2",
    icon: "bi bi-vector-pen",
    title: "Graphics",
    desc: "Elevate your brand's visual identity with our creative graphic design solutions, tailored to leave a lasting impression and drive engagement. Let us bring your ideas to life with captivating visuals that resonate with your audience.",
    hashlinks: "service_detail",
    properties: ["Logo Designing", "Banner Poster Designs", "Figma Designs"],
    links: ["/services/5", "/services/6", "/services/8"],
    data_aos_delay: "600",
  },
  {
    id: "3",
    icon: "fas fa-palette",
    title: "Print Media",
    desc: "Uplift your marketing efforts with our print media services, designed to captivate audiences and leave a lasting impression. Let us transform your message into tangible, materials that drive results.",
    hashlinks: "service_detail",
    properties: ["Print Media (Trending)"],
    links: ["/services/7"],
    data_aos_delay: "900",
  },

  {
    id: "4",
    icon: "bi bi-diagram-3",
    title: "Online Marketing",
    desc: "Boost your digital presence with our comprehensive online marketing solutions, tailored to drive engagement and increase brand visibility. Let us amplify your reach and impact in the digital realm.",
    hashlinks: "service_detail",
    properties: [
      "Digital Marketing",
      "Social Media Marketing",
      "Content Marketing",
    ],
    links: ["/services/9", "/services/10", "/services/12"],
    data_aos_delay: "900",
  },
  {
    id: "5",
    icon: "fab fa-google",
    title: "Google Ads",
    desc: "Reach your target audience effectively with Google Ads, the powerful advertising platform that maximizes your online visibility and drives conversions. Let us optimize your campaigns for maximum ROI and business growth.",
    hashlinks: "service_detail",
    properties: ["Google Ads Campaign"],
    links: ["/services/11"],
    data_aos_delay: "900",
  },
  {
    id: "6",
    icon: "fas fa-copyright",
    title: "Branding",
    desc: "Expertise a distinctive identity and leave a lasting impression with our branding solutions, designed to elevate your brand's recognition and reputation. Let us help you stand out in the market and connect with your audience on a deeper level.",
    hashlinks: "service_detail",
    properties: [
      "Brand Identity",
      "Mockup Design",
      "Visual Design",
      "Illustration & Iconography",
    ],
    links: ["/services/13", "/services/14", "/services/15", "/services/16"],
    data_aos_delay: "900",
  },
];
